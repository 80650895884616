
.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a  {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #161615;
    color: #161615;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #f8d04b;
  }
  
  .paginationActive a {
    color: white;
    background-color: #f3ae48;
  }
  
  .paginationDisabled a {
    color: #050505;
    background-color: rgb(255, 254, 254);
  }