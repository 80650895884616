.bimg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;}

    .p{
      font-size: 45px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      font-weight: bold;
      color: #171717;}

  .p1{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-justify: inter-word;
    font-size: 19px;
    color: grey;
    padding: 0;}

  .btn1{
    background: transparent linear-gradient(90deg, #FBB040 0%, #FBB040 4%, #FEC007 39%, #FFDE17 100%) 0% 0% no-repeat padding-box;
    border-radius: 13px;
    top: 800px;
    left: 650px;
    width: 150px;
    height: 50px;
    text-align: center;
    font: normal normal bold 20px/24px 'Montserrat';
    letter-spacing: 0px;
    color: #2B2A28;
    opacity: 1;
    display: inline;
    margin-right: 25px;
    cursor: pointer;}

  .btn2{
    background: transparent linear-gradient(90deg, #4D4D4D 0%, #000000 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #00000026;
    border-radius: 13px;
    top: 800px;
    left: 650px;
    width: 150px;
    height: 50px;
    font: normal normal bold 20px/24px 'Montserrat';
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    text-align: center ;
    display: inline;
    cursor: pointer;}

  .box{
      display: flex;
      justify-content: center;}